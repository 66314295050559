import React, { Component } from "react";
import { getProducts } from "../services/images";

class ProductListComponent extends Component {
  state = {
    products: getProducts(),
    currentProduct: {
      _id: 1,
      name: "Strawberry Crunch",
      url: "pexels-julias-torten-und-törtchen-1179002.jpg",
    },
  };

  render() {
    const { products, currentProduct } = this.state;
    return (
      <>
        <div className="product-banner">
          <h2 className="text-center text-6xl mt-10 font-black text-secondary">
            April's Cakes
          </h2>
        </div>

        <div className="bg-white">
          <div className="mx-auto max-w-2xl py-16 px-4 sm:py-24 sm:px-6 lg:max-w-7xl lg:px-8">
            <h2 className="sr-only">Products</h2>
            <div className="grid grid-cols-1 gap-y-10 gap-x-6 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 xl:gap-x-8">
              {products.map((product) => (
                <a key={product._id} href="#" className="group">
                  <div className="aspect-w-1 aspect-h-1 w-full overflow-hidden rounded-lg bg-gray-200 xl:aspect-w-7 xl:aspect-h-8">
                    <img
                      src={require(`../images/${product.url}`)}
                      className="h-full w-full object-cover object-center group-hover:opacity-75"
                    />
                  </div>
                  <h3 className="mt-4 mb-1 text-xl font-semibold text-gray-700">
                    {product.name}
                  </h3>
                  <div className="grid grid-cols-8">
                    <p className="mt-1 text-lg font-medium text-gray-900 col-span-5">
                      ${product.price} per dozen
                    </p>
                    <button className="bg-blue-600 rounded-xl text-white col-span-3 shadow-md shadow-black ">
                      Add To Order
                    </button>
                  </div>
                </a>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductListComponent;
