import React, { Component } from "react";
import NavigationComponent from "./components/navigationComponent";
import ProductComponent from "./components/productComponent";
import ProductListComponent from "./components/productListComponent";

import ContactForm from "./components/contactForm";

function App() {
  return (
    <>
      <div className="hero">
        <div className="min-h-screen flex flex-col text-white">
          <main className="container mx-auto px-6 pt-16 flex-1 text-center">
            <h2 className="text-2xl md:text-4xl lg:text-6xl uppercase">
              Welcome To
            </h2>
            <h2 className="text-3xl md:text-5xl lg:text-7xl uppercase font-black mb-8">
              April's Cakes
            </h2>
            <div className=" mt-56">
              <h3 className="text-2xl font-bold">Southern Indiana's</h3>
              <h3 className="font-black text-4xl">Best Kept Secret</h3>
            </div>
          </main>
          <footer className="container mx-auto p-6 flex flex-col md:flex-row items-center justify-between">
            <p>Built By Web Dezign Solutions</p>
            <div className="flex -mx-6">
              {/* <a href="#" className="mx-3 hover:opacity-80 duration-150">
                About Us
              </a>{" "}
              |
              <a href="#" className="mx-3 hover:opacity-80 duration-150">
                Order
              </a>{" "}
              |
              <a href="#" className="mx-3 hover:opacity-80 duration-150">
                Contact Us
              </a> */}
            </div>
          </footer>
        </div>
      </div>
      {/* <ProductComponent /> */}
      <NavigationComponent />
      <ProductListComponent />

      {/* <ProductListComponent /> */}

      {/*<ContactForm /> */}
    </>

    // <div className="hero flex flex-col">
    //   {/* <NavigationComponent /> */}

    //   {/* <SingleProductComponent /> */}
    //   <main className="container mx-auto min-h-screen text-white flex-1 px-6 text-center">

    //   </main>
    //   <footer className="container mx-auto p-6 flex flex-col md:flex-row items-center justify-between text-white">
    //     <p>Built with Love Brandon McGhee</p>

    //     <div className="flex -mx-6 ">
    //       <a href="#" className="mx-3 hover:opacticty-80 duration-150">
    //         About Us
    //       </a>
    //       |
    //       <a href="#" className="mx-3 hover:opacticty-80 duration-150">
    //         Privacy
    //       </a>
    //       |
    //       <a href="#" className="mx-3 hover:opacticty-80 duration-150">
    //         Contact
    //       </a>
    //     </div>
    //   </footer>
    // </div>
  );
}

export default App;
