export const products = [
  {
    _id: 1,
    name: "Reese's",
    url: "reeses.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
  {
    _id: 2,
    name: "Chocolate Chip Cookie Dough",
    url: "chocolate_chip_cookie_dough.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
  {
    _id: 3,
    name: "Red Velvet",
    url: "red_velvet.jpg",
    price: 20,
  },
  {
    _id: 4,
    name: "Butterfinger",
    url: "butterfinger.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
  {
    _id: 5,
    name: "Vanilla",
    url: "vanilla.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
  {
    _id: 6,
    name: "Strawberry Crunch",
    url: "strawberry_crunch.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
  {
    _id: 7,
    name: "Birthday Cake",
    url: "birthday_cake.jpg",
    category: { _id: 1, name: "Cupcake" },
    price: 20,
  },
];

export function getProducts() {
  return products.filter((p) => p);
}
